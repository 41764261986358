import React from "react"
import { Link } from "gatsby"
import { Box, Badge, Image, Grid } from "@chakra-ui/core"
import Layout from "../../../components/layoutSurpriseCustom"
import BgImg from "../../../images/GH_Big.jpg"
import GH from "../../../images/GH_Square.jpg"
import More from "../../../images/moreSermons.jpg"
import SEO from "../../../components/seo"
import { css } from "@emotion/core"

function SermonBox({
  imageUrl,
  imageAlt,
  sermonTitle,
  sermonLink,
  date,
  badge,
  pastor,
  sermonSeries,
  badgeColor,
}) {
  return (
    <Box
      as={Link}
      to={sermonLink}
      w="auto"
      h="auto"
      borderWidth="1px"
      rounded="lg"
      overflow="hidden"
      bg="#edf2f7"
      boxShadow="md"
      css={css`
        text-decoration: none;
        transition: 0.3s;
        &:hover {
          box-shadow: 4px 8px 16px 4px rgba(0, 0, 0, 0.2);
        }
      `}
    >
      <Image src={imageUrl} alt={imageAlt} w="100%" />

      <Box p="6">
        <Box alignItems="baseline">
          <Badge rounded="lg" px="8" py="2" variantColor={badgeColor} mt="0">
            {badge}
          </Badge>
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            mt="3"
          >
            {sermonSeries} &bull; {pastor}
          </Box>
        </Box>
        <Box
          fontWeight="bold"
          as="h2"
          lineHeight="tight"
          isTruncated
          color="gray.900"
        >
          {sermonTitle}
        </Box>

        <Box color="gray.600" fontSize="sm">
          {date}
        </Box>
      </Box>
    </Box>
  )
}

function SermonBoxExternal({
  imageUrl,
  imageAlt,
  sermonTitle,
  sermonLink,
  date,
  badge,
  pastor,
  sermonSeries,
  badgeColor,
}) {
  return (
    <Box
      as="a"
      href={sermonLink}
      w="auto"
      h="auto"
      borderWidth="1px"
      rounded="lg"
      overflow="hidden"
      bg="#edf2f7"
      boxShadow="md"
      css={css`
        text-decoration: none;
        transition: 0.3s;
        &:hover {
          box-shadow: 4px 8px 16px 4px rgba(0, 0, 0, 0.2);
        }
      `}
    >
      <Image src={imageUrl} alt={imageAlt} w="100%" />

      <Box p="6">
        <Box alignItems="baseline">
          <Badge rounded="lg" px="8" py="2" variantColor={badgeColor} mt="0">
            {badge}
          </Badge>
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            mt="3"
          >
            {sermonSeries} &bull; {pastor}
          </Box>
        </Box>
        <Box
          fontWeight="bold"
          as="h2"
          lineHeight="tight"
          isTruncated
          color="gray.900"
        >
          {sermonTitle}
        </Box>

        <Box color="gray.600" fontSize="sm">
          {date}
        </Box>
      </Box>
    </Box>
  )
}

const IndexPage = () => (
  <Layout
    ImgSrc={BgImg}
    MainText="Greatest Hits"
    SecondText="Check out sermons from our Greatest Hits sermon series"
  >
    <SEO title="Sermons - Greatest Hits" />
    <Grid
      templateColumns={[
        "repeat(1, 1fr)",
        "repeat(1, 1fr)",
        "repeat(2, 1fr)",
        "repeat(3, 1fr)",
      ]}
      gap={10}
    >
                                          <SermonBox
        imageUrl={GH}
        imageAlt="Greatest Hits Sermon Series"
        sermonTitle="Psalm 23"
        sermonSeries="Greatest Hits"
        sermonLink="/surprise/sermons/psalm-23"
        date="September 17th, 2023"
        pastor="Pastor Jackie"
      />
                                      <SermonBox
        imageUrl={GH}
        imageAlt="Greatest Hits Sermon Series"
        sermonTitle="How Great Thou Art"
        sermonSeries="Greatest Hits"
        sermonLink="/surprise/sermons/how-great-thou-art"
        date="September 10th, 2023"
        pastor="Pastor Jackie"
      />
                                  <SermonBox
        imageUrl={GH}
        imageAlt="Greatest Hits Sermon Series"
        sermonTitle="A to Z Guide for Life"
        sermonSeries="Greatest Hits"
        sermonLink="/surprise/sermons/a-to-z-guide-for-life"
        date="September 3rd, 2023"
        pastor="Pastor Jackie"
      />
                              <SermonBox
        imageUrl={GH}
        imageAlt="Greatest Hits Sermon Series"
        sermonTitle="#BUTGOD"
        sermonSeries="Greatest Hits"
        sermonLink="/surprise/sermons/butgod"
        date="August 27th, 2023"
        pastor="Jeremy Freeman"
      />
                          <SermonBox
        imageUrl={GH}
        imageAlt="Greatest Hits Sermon Series"
        sermonTitle="Friends in Low Places"
        sermonSeries="Greatest Hits"
        sermonLink="/surprise/sermons/friends-in-low-places"
        date="August 20th, 2023"
        pastor="Pastor Jackie"
      />
                      <SermonBox
        imageUrl={GH}
        imageAlt="Greatest Hits Sermon Series"
        sermonTitle="Church Rescue 2023"
        sermonSeries="Greatest Hits"
        sermonLink="/surprise/sermons/church-rescue-2023"
        date="August 13th, 2023"
        pastor="David McClain"
      />
                  <SermonBox
        imageUrl={GH}
        imageAlt="Greatest Hits Sermon Series"
        sermonTitle="A Hymn of Praise"
        sermonSeries="Greatest Hits"
        sermonLink="/surprise/sermons/hymn-of-praise"
        date="August 6th, 2023"
        pastor="Pastor Tyler"
      />
              <SermonBox
        imageUrl={GH}
        imageAlt="Greatest Hits Sermon Series"
        sermonTitle="Everything is Not Ok"
        sermonSeries="Greatest Hits"
        sermonLink="/surprise/sermons/not-ok"
        date="July 30th, 2023"
        pastor="Pastor Chad"
      />
          <SermonBox
        imageUrl={GH}
        imageAlt="Greatest Hits Sermon Series"
        sermonTitle="How to Live a Happy Life"
        sermonSeries="Greatest Hits"
        sermonLink="/surprise/sermons/happy-life"
        date="July 16th, 2023"
        pastor="Pastor Jackie"
      />
      <SermonBox
        imageUrl={More}
        imageAlt="view more sermons"
        sermonTitle="View More Sermons"
        sermonLink="/surprise/sermons"
        sermonSeries="All Sermons"
        pastor="Cross Church Phoenix"
      />
    </Grid>
  </Layout>
)

export default IndexPage
